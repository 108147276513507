import signinImg from "./signimage.jpg";
import signupImg from "./signupimage.jpg";
import forgotpassImg from "./forgotpassword.jpg";
import dArraow from "./keyboard-down-arrow-1780093-1518654.png";
import downArrow from "./downarrow.png";
import avatar from "./avatar.png";
import Banner from "./Survey_banner.jpg";
import InnerOne from "./inner_1.jpg";
import InnerTwo from "./inner_2.jpg";
import InnerThree from "./inner_3.jpg";
import ServiceOne from "./services_1.png";
import ServiceTwo from "./services_2.png";
import SurveyImg from "./survey-img.png";
import SurveyPng from "./survey.png";
import dislike from "./dislike.png";
import like from "./like.png";
import bsaLogo_admin from "./bsaLogo_admin.png";
import bsaLogo from "./bsaLogo.png";
import refresh from "./refresh.png";
//import logo1 from "./logo1.png";

import brand1 from "./brand1.png";
import brand2 from "./brand2.png";
import brand3 from "./brand3.png";
import brand4 from "./brand4.png";
import brand5 from "./brand5.png";
import finallogo from "./finallogo.png";
import client from "./client.jpg";
import quote from "./quote.svg";

import Darrh_Bullock from "./Darrh-Bullock.jpg";
import Les_Anderson from "./Les-Anderson.jpg";
import Jimmy_Henning from "./Jimmy-Henning.jpg";
import Kevin_Laurent from "./Kevin-Laurent.jpg";
import Jeff_Lehmkuhler from "./Jeff-Lehmkuhler.jpg";
import Katie_VanValin from "./Katie-VanValin.jpg";
import user_default from "./user_default.jpg";
import survey1 from "./survey1.jpg";
import survey2 from "./survey2.jpg";
import survey3 from "./survey3.jpg";
import JohnProfile from "./JohnProfile.png";

import gallery01 from "./gallery01.jpg";
import gallery02 from "./gallery02.jpg";
import gallery03 from "./gallery03.jpg";
import gallery04 from "./gallery04.jpg";
import gallery05 from "./gallery05.jpg";
import gallery06 from "./gallery06.jpg";
import gallery07 from "./gallery07.jpg";
import gallery08 from "./gallery08.jpg";

import banar1 from "./banner1.jpg";
import banar2 from "./banner2.jpg";
import banar3 from "./banner3.jpg";

import ukyLogo from "./uky.svg";
import gold from "./gold.png";

import contactus from "./contactus.png";

export {
  JohnProfile,
  bsaLogo,
  finallogo,
  signinImg,
  signupImg,
  forgotpassImg,
  dArraow,
  downArrow,
  avatar,
  Banner,
  InnerOne,
  InnerTwo,
  InnerThree,
  ServiceOne,
  ServiceTwo,
  SurveyImg,
  SurveyPng,
  like,
  dislike,
  bsaLogo_admin,
  brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  client,
  quote,
  Darrh_Bullock,
  Les_Anderson,
  Jimmy_Henning,
  Kevin_Laurent,
  Jeff_Lehmkuhler,
  Katie_VanValin,
  user_default,
  survey1,
  survey2,
  survey3,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  ukyLogo,
  banar1,
  banar2,
  banar3,
  gold,
  contactus,
  refresh,
};
