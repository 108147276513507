import React, { Component } from "react";
import { avatar } from "../../../assets/images";
import { Link } from "react-router-dom";
import axios from "../axios/axios";
import Tooltip from "react-bootstrap/Tooltip";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Badge} from "react-bootstrap";
import "./layout.css";

export class Rightpanel extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken");
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.state = {
      userDetails: "",
      profileImg: "",
      alluserrecommendations: [],
      membershipdetails: [],
      totalRec: 0,
    };
  }

  componentDidMount() {
    //console.log("token from user profile",this.props );
    this.fetchUserDetails();
    this.fetchMembership();
  }
  componentWillUpdate(prevProps,prevState){
    if(prevState.alluserrecommendations === this.state.alluserrecommendations){
           this.fetchUserDetails();
         }
  }
  // componentDidUpdate(prevProps,prevState){
  //   if(prevState.alluserrecommendations.rec_options !== this.state.alluserrecommendations.rec_options){
  //     this.fetchUserDetails();
  //   }
  // }
  fetchUserDetails = (inputValue) => {
    //console.log("I am here", inputValue)
    axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/user-and-servey-count",

      headers: {
        "x-auth": this.token,
      },
    })
      .then(async (res) => {
        if (res.data) {
          this.setState({
            alluserrecommendations: res.data.alluserrecommendations,
            userDetails: res.data,
            profileImg: res.data.userdata.image,
          });

          //console.log('ddddd',this.state.userDetails.userdata.image)
        }
      })
      .catch((err) => {
        // console.log("error", err);
      });
  };
  fetchMembership = (inputValue) => {
    //console.log("I am here", inputValue)
    axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/membership",

      headers: {
        "x-auth": this.token,
      },
    })
      .then(async (res) => {
        if (res.data) {
          // console.log("membership", res.data);
          this.setState({
            membershipdetails: res.data.details,
          });

          //console.log('ddddd',this.state.userDetails.userdata.image)
        }
      })
      .catch((err) => {
        // console.log("error", err);
      });
  };
  calculateRecommendations = () =>{
    let recs =[];
    this.state.alluserrecommendations.map((item,i)=>{
      if(item.rec_options !== "Yes" && item.reason_uploaded !== "true" ){ 
        recs.push(item);
      }
      return true;
    })
    if(recs.length){
      return recs.length;
    } else {
      return 0;
    }
  }
  calculatepoints = () => {
    let point = 0;
    this.state.alluserrecommendations.forEach((item) => {
      if(item.rec_options !== "Yes" && item.reason_uploaded !== "true" ){
      point += item.difficulty;
      }
    });
    return point;
  };
  calculatemembership = () => {
    let point = this.calculatepoints();
    let membership = "---",
      batch = "---";
    let compare = (a, b) => {
      if (a.point < b.point) {
        return -1;
      }
      if (a.point > b.point) {
        return 1;
      }
      return 0;
    };

    this.state.membershipdetails.sort(compare);
    let i = 0;
    for (i = 0; i < this.state.membershipdetails.length; i++) {
      if (point < this.state.membershipdetails[i].point) {
        if (i > 0) {
          membership = this.state.membershipdetails[i - 1].name;
          batch = this.state.membershipdetails[i - 1].batch_image;
        }

        break;
      }
    }
    if (this.state.membershipdetails.length) {
      if (i === this.state.membershipdetails.length) {
        membership = this.state.membershipdetails[i - 1].name;
        batch = this.state.membershipdetails[i - 1].batch_image;
      }
    }

    return [membership, batch];
  };
  renderTooltipObj = () => {
    let obj = { name: "" };
    this.state.membershipdetails.forEach((item) => {
      obj.name = [item.name, item.point];
    });
    return obj;
  };
  renderTooltip = (props) => (
    <Tooltip {...props}>
      {props.map((item) => {
        return (
          <p>
            {item.name} if your point is &gt;= {item.point}
          </p>
        );
      })}
    </Tooltip>
  );

  render() {
   // console.log("header", this.renderTooltipObj());
    return (
      <div className="col-md-4">
        <div className="card card-profile">
          <div className="card-profile-image text-center p-3">
            <a href="/user-profile" className="profile-image">
              <img
                src={
                  this.state.profileImg !== "" && this.state.profileImg !== null
                    ? `https://api.x10dadmin.com/storage/${this.state.profileImg}`
                    : avatar
                }
                alt="img"
                className="rounded-circle img-responsive"
              />
              {/* <div className="user__type">
                {this.calculatemembership()[0] == "---" ? (
                  ""
                ) : (
                  <img
                    width="30"
                    height="30"
                    src={this.calculatemembership()[1]}
                    className="img-fluid"
                  />
                )}
              </div> */}
            </a>

            {/* <h5>
              <OverlayTrigger
                placement="top"
                overlay={this.renderTooltip(this.state.membershipdetails)}
              >
                <i className="fa fa-info-circle font-custom-size mt-2"></i>
              </OverlayTrigger>
              &nbsp; Membership: {this.calculatemembership()[0]}
            </h5> */}
          </div>
          <div className="card-body text-center">
            <div className="row">
              <div className="col">
                <div className="card-profile-stats">
                  <div className="heading d-flex justify-content-center">
                  <Badge bg="primary" pill>{this.state.userDetails
                      ? this.state.userDetails.totalSurvey
                      : 0}</Badge>
                  </div>
                  <div className="description">Analyzer</div>
                </div>
              </div>

              <div className="col">
                <div className="card-profile-stats">
                  <div className="heading d-flex justify-content-center">
                    {/* {this.state.totalRec && this.state.totalRec
                      ? this.state.totalRec
                      : 0} */}
                      <Badge bg="primary" pill>{this.calculateRecommendations()}</Badge>
                  </div>
                  <div className="description">Recommendation</div>
                </div>
              </div>

              {/* <div className="col">
                <div className="card-profile-stats">
                  <div className="heading d-flex justify-content-center">
                  <Badge bg="primary" pill>{this.calculatepoints()}</Badge>
                  </div>
                  <div className="description">Recommendation Points</div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="text-center p-3">
            {/* <h3>{this.state.name}</h3> */}
            {/* <div className="h5 font-weight-300">
                <i className="fa fa-map-marker" aria-hidden="true"></i> {this.state.address}
              </div> */}

            <div className="list-group">
              <Link
                to={`user-profile`}
                className={`list-group-item list-group-item-action ${
                  this.props.activePath === "userprofile" ? "active" : ""
                }`}
              >
                My Profile
              </Link>
              <Link
                to={`user-farm`}
                className={`list-group-item list-group-item-action ${
                  this.props.activePath === "userfarm" ? "active" : ""
                }`}
              >
                My Farm
              </Link>
              <Link
                to={`user-servey`}
                className={`list-group-item list-group-item-action ${
                  this.props.activePath === "surveylist" ? "active" : ""
                }`}
              >
                My Analyzers
              </Link>
              
              {/* client changes recommendation to be implemented */}
              
              {/* <Link
                to={{ pathname: `recommendation-implemented` }}
                className={`list-group-item list-group-item-action ${
                  this.props.activePath === "recommendationImplemented" ? "active" : ""
                }`}
              >
                Recommendations to be Implemented
              </Link> */}
              <Link
                to={{ pathname: `user-recommendation` }}
                className={`list-group-item list-group-item-action ${
                  this.props.activePath === "recommendataionlist" ? "active" : ""
                }`}
              >
                Recommendations to be Implemented
              </Link>
              <Link
                to={{ pathname: `implemented-recommendations` }}
                className={`list-group-item list-group-item-action ${
                  this.props.activePath === "implementedRecommendation" ? "active" : ""
                }`}
              >
                Implemented Recommendations
              </Link>
              <Link
                to={{ pathname: `recommendation-declined` }}
                className={`list-group-item list-group-item-action ${
                  this.props.activePath === "recommendationDeclined" ? "active" : ""
                }`}
              >
                Recommendations Declined to Implement
              </Link>
              
              {/* <Link
                to={`user-progress`}
                className={`list-group-item list-group-item-action ${
                  this.props.activePath === "userprogress" ? "active" : ""
                }`}
              >
                My Progress Status
              </Link>
              <Link
                to={`achievements`}
                className={`list-group-item list-group-item-action ${
                  this.props.activePath === "achievements" ? "active" : ""
                }`}
              >
                My Achievements
              </Link>
              <Link
                to={`bucket`}
                className={`list-group-item list-group-item-action ${
                  this.props.activePath === "bucket" ? "active" : ""
                }`}
              >
                My Bucket
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rightpanel;
