import React, { Component } from "react";

import { toast, ToastContainer } from "react-toastify";
import reacthtmlparser from "react-html-parser";
import { Link } from "react-router-dom";
import "../components/slider/slider.css";

import "antd/dist/antd.css";
import Header from "../components/headers/Header";
import Footer from "../components/footers/Footer";
import Rightpanel from "../layouts/Rightpanel";
import LoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";
import { ListGroup } from 'react-bootstrap';
import axios from "../axios/axios"; 
import "./userprogress.css";
import "./layout.css";
import {seo} from "../../helpers/seo";

export class RecommendationDeclined extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken");
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.state = {
      loader: false,
      recommendationid: null,
      recommentaionList: "",
      // analyzers:[],
      show: false,
      showExist: false,
      showRecommendation: false,
      surveyid: null,
      reason: {},
      reasonerr:{},
      index: null,
      fadeOut: {},
      reasonState: false,
      rec_id: "",
      
      // in progress data
      analyzers:[],
      recommendations:[],
      isDisabled: true,
    };
  }

  componentDidMount() {
    this.fetch_UserRecommendations();
    seo({
        title: 'Declined Recommendation'
      });
  }
  fetch_UserRecommendations = () =>{
    this.setState({ loader: true });
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/recommendation-to-be-implement",

      headers: {
        "x-auth": this.token,
      },
    })
    .then((res)=>{
        //console.log("rec",res.data)
        if(res){
            this.setState({
                analyzers: res.data.analyzer,
                recommendations: res.data.data,
                loader: false,
            })
        }
        if(res.data.analyzer.length > 0 && res.data.data.length > 0){
          this.setState({ isDisabled: false });
        }
    })
    .catch((err)=>{
      this.setState({ loader: false });
        console.log("error", err);
    })
}

onChangeValue = (id, surveyid, event) => {
  // console.log("--event--, --surveyid--, --id-- ", event.target.value, surveyid, id)
  this.setState({reasonState: true, rec_id: id});
    this.setState({
      loader: true,
    });
    axios
      .post(
        process.env.REACT_APP_API_URL + "/user-recommendation-options-update",
        {
          recommendations_id: id,
          survey_id: surveyid,
          rec_options: event.target.value,
        },
        {
          headers: {
            "x-auth": this.token,
          },
        }
      )
      .then((res) => {
        //console.log(res.data); 
        this.setState({ loader: false});
        if(event.target.value === "Yes"){
          this.saveAchievement(id, surveyid);
          let fade = this.state.fadeOut;
          fade[id] = true;
          this.setState({ fadeOut: fade});
          // console.log(this.state.fadeOut[id])
          // toast.success("Recommendation moved to implemented recommendation");
          toast.success("Recommendation removed from this list");
          setTimeout(()=>{
            this.fetch_UserRecommendations();
          },250)
        }else if(event.target.value === "In Progress" || event.target.value === "Still Plan To"){
          // toast.success("Recommendation moved to recommendation to be implemented");
          toast.success("Recommendation removed from this list");
          setTimeout(()=>{
            this.fetch_UserRecommendations();
          },250)
        }else{
          this.fetch_UserRecommendations();
        }
      })
      .catch((err) => {
        // console.log("error", err);
        this.setState({ loader: false });
      });
};

saveAchievement = (id, surveyid) => {
  this.setState({reasonState: true, rec_id: id});
    this.setState({
      loader: true,
    });
    axios
      .post(
        process.env.REACT_APP_API_URL + "/save-achievements",
        {
          recommendations_id: id,
          survey_id: surveyid,
        },
        {
          headers: {
            "x-auth": this.token,
          },
        }
      )
      .then((res) => {
        //console.log(res.data); 
        this.setState({ loader: false});
      })
      .catch((err) => {
        // console.log("error", err);
        this.setState({ loader: false });
      });
};
 
  recommendationDeclinedPdf = () => {
    this.props.history.push({
      pathname: "/recommendation-declined-pdf",
      // state: {
      //   survey_id: uid,
      // },
    });
  };
  
  render() {
    const { loader, analyzers, recommendations } = this.state;
    console.log("analyzers, recommendations...", analyzers, recommendations);
    return (
      <React.Fragment>
        <Header />
        <div className="main-content">
          <LoadingOverlay
            active={loader}
            spinner={<BeatLoader color="#fff" size={30} />}
          >
          
            <div className="container">
              <div className="row">
                <Rightpanel activePath="recommendationDeclined" />
                <div className="col-md-8">
                    <div className="card">
                    <div className="card-header bg-white border-0">
                      <div className="row align-items-center justify-content-between">
                          <div className="col-8">
                            <h3 className="mb-0">Recommendations declined to implement</h3>
                          </div>
                          {/* <button
                            className="btn-secondary px-4 py-2"
                            style={{border: "0px", background: "#2eb85c", color: "white"}}
                            onClick={this.recommendationDeclinedPdf}
                          >
                            Download and Print
                          </button> */}
                          {
                            this.state.isDisabled === true ?
                            <React.Fragment>
                            <button
                              className="px-4 py-2"
                              style={{border: "0px", background: "#a1e6b8", color: "white"}}
                              onClick={this.recommendationDeclinedPdf}
                              disabled={this.state.isDisabled}
                            >
                            Download and Print
                            </button>
                            </React.Fragment> : 
                            <React.Fragment>
                            <button
                              className="px-4 py-2"
                              style={{border: "0px", background: "#2eb85c", color: "white"}}
                              onClick={this.recommendationDeclinedPdf}
                            >
                            Download and Print
                            </button>
                            </React.Fragment>
                          }
                      </div>
                    </div>
                    <div className="card-body">
                   
                    { 
                      analyzers && analyzers.map((item,i)=>{
                        return(
                          <div key={i}>
                            <ListGroup as="ol">
                              <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between mb-3"
                              >
                                <div className="ms-2 me-auto w-100">
                                  <div className='d-flex'>
                                  {/* { 
                                    recommendations && recommendations.map((rec,i)=>{
                                      return(
                                        rec.bfanalyzer_survey_id === item.bfanalyzer_survey_id && rec.content !== "" && rec.name !== "" && rec.rec_options === "Decided Not To"  ?
                                        <React.Fragment>
                                          <h5><b className='text-nowrap mr-2'>{i+1}. Analyzer: </b></h5>
                                    
                                          <div className="ana-title">{item.title}</div>
                                        </React.Fragment> : <React.Fragment></React.Fragment>
                                      )}
                                    )
                                  } */}
                                  
                                        <h5><b className='text-nowrap mr-2'>{i+1}. Analyzer: </b></h5>
                                    
                                        <div className="ana-title">{item.title}</div>
                                      
                                      {/* <OverlayTrigger placement="left" overlay={analyzerTooltip}>
                                          <Badge bg="primary" pill>{item.max_difficulty}</Badge>
                                      </OverlayTrigger> */}
                                      
                                  </div>
                                  
                                  <div className='rec-list'>
                                  { 
                                    recommendations && recommendations.map((rec,i)=>{
                                      return(
                                        rec.rec_options === "Decided Not To" ?
                                          <>
                                            <div key={i}>
                                              {
                                                rec.bfanalyzer_survey_id === item.bfanalyzer_survey_id ?
                                                  <div className="rec-item">
                                                    <span><b>Recommendation- </b>{rec.name}</span>
                                                    <br />
                                                    <b>Content: </b>
                                                    {
                                                      rec.content?.length>0 ? reacthtmlparser(rec.content.replace(/href/g, "target='_blank' href")) : "No content provided"
                                                    }
                                                    <h5 className="mt-2">
                                                      Did you implement this recommendation?
                                                    </h5>
                                                    {/* <div className="radio_group disabled-cursor mb-2"> */}
                                                    <div className="radio_group mb-3" onChange={this.onChangeValue.bind(this, rec.recommendations_id, rec.bfanalyzer_survey_id)}>
                                                      <label><input checked={rec.rec_options === "Yes"} type="radio" onChange={e => {}} value="Yes"/>Yes<span className="check__mark"></span></label>
                                                      <label><input checked={rec.rec_options === "In Progress"} type="radio" onChange={e => {}} value="In Progress"/>In Progress<span className="check__mark"></span></label>
                                                      <label><input checked={rec.rec_options === "Still Plan To" } type="radio" onChange={e => {}} value="Still Plan To"/>Still Plan To<span className="check__mark"></span></label>
                                                      <label><input checked={rec.rec_options === "Decided Not To" } type="radio" onChange={e => {}} value="Decided Not To"/>Decided Not To<span className="check__mark"></span></label>
                                                    </div>
                                                </div>
                                              :<></>
                                            }
                                          </div>
                                        </> : ""
                                        
                                      )
                                    })
                                  }
                                  </div>
                                
                                </div>
                                  
                              </ListGroup.Item>
                            </ListGroup>
                          </div>
                        )
                      })
                    }
                   
                    </div>
                  </div>
                  {/* <Link to={`/survey-list`} className="btn btn-success">
                    Continue
                  </Link> */}
                </div>
              </div>
            </div>
            <ToastContainer />
          </LoadingOverlay>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default RecommendationDeclined;