import React, { useState, useEffect } from 'react'
import Header from "../components/headers/Header";
import axios from "../axios/axios";
import RecommendationModal from "./modals/recommendationModal";
import LoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";
import Rightpanel from "../layouts/Rightpanel";
import { ListGroup } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import {seo} from '../../helpers/seo';
import "./layout.css";
import Footer from '../components/footers/Footer';
import { ToastContainer } from 'react-toastify';

function ImplementedRecommendation(props) {
    const [page, setPage] = useState(1)
    // const [limit, setLimit] = useState(4)
    const [totalPages, setTotalPages] = useState(0)
    const [achievements, setAchievements ] = useState([]);
    const [analyzers, setAnalyzers ] = useState([]);
    const [show, setShow ] = useState(false);
    const [rec_id, setRec_id ] = useState('');
    const [loader, setLoader] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true);
    const limit = 6;

    let token = localStorage.getItem("authToken");
    let userdata = JSON.parse(localStorage.getItem("userdata"))
  
    const getBucket = async() =>{
        setLoader(true);
        await axios
        //   .get(`/bucket?limit=${limit}&page=${page}`,
          .get(`/bucket?page=${page}`,
          {
              headers: {"x-auth": token}
          })
          .then((res)=>{
            setLoader(false);
              // console.log("bucket", res.data)
              
              if(res.data.status === "success"){
                  setAnalyzers(res.data.analyzers);
                  setAchievements(res.data.achievements);
                  setPage(res.data.pagination.current_page);
                  setTotalPages(res.data.pagination.total_pages)
              }
              if(res.data.analyzers.length > 0 && res.data.achievements.length > 0){
                setIsDisabled(false);
              }
              // console.log("=================", analyzers)
          })
          .catch((err)=>{
            setLoader(false);
            console.log("error", err);
          })
      }
      
      const handleShowRecommendation = (recommendationid) => {
        setShow(!show);
        setRec_id(recommendationid)
      };
    
      let onPageChange = (page) => {
        let page1 = page.selected + 1;
        setPage(page1);
      }
      
    useEffect(() => {
        getBucket();
        seo({
        title: 'Implemented Recommendation'
        });
    },[page]);
    
    const bucketDownloadPdf = () => {
        props.history.push({
        pathname: "/implemented-recommendation-pdf",
        // pathname: "/bucket-pdf",
        // state: {
            
          // }
        });
      };
  
    return (
        <React.Fragment>
        <Header />
        <div className="main-content">
            <LoadingOverlay
            active={loader}
            spinner={<BeatLoader color="#fff" size={30} />}
            //text='Loading your profile...'
            >
            <RecommendationModal
                show={show}
                handleShow={handleShowRecommendation}
                recommendationid={rec_id}
            /> 
            <div className="container">
                <div className="row">
                <Rightpanel activePath="implementedRecommendation" />
                <div className="col-md-8">
                    <div className="card">
                            <div className="card-header bg-white border-0">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-8">
                                        <h3 className="mb-0">Implemented recommendations</h3>
                                    </div>
                                    {
                                        isDisabled === true ?
                                        <React.Fragment>
                                        <button
                                            className="px-4 py-2"
                                            style={{border: "0px", background: "#a1e6b8", color: "white"}}
                                            onClick={bucketDownloadPdf}
                                            disabled={isDisabled}
                                        >
                                        Download and Print
                                        </button>
                                        </React.Fragment> : 
                                        <React.Fragment>
                                        <button
                                            className="px-4 py-2"
                                            style={{border: "0px", background: "#2eb85c", color: "white"}}
                                            onClick={bucketDownloadPdf}
                                        >
                                        Download and Print
                                        </button>
                                        </React.Fragment>
                                    }
                                    
                                </div>
                            </div>
                            <div className="card-body">
                            { analyzers && analyzers.map((item,i)=>{
                                return(
                                <div key={i}>
                                    <ListGroup as="ol">
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between mb-3"
                                    >
                                        <div className="ms-2 me-auto w-100">
                                        <div className='d-flex'>
                                            <h5><b className='text-nowrap mr-2'>{i+1}. Analyzer: </b></h5>
                                        
                                            <div className="ana-title">{item.title}
                                            {/* <OverlayTrigger placement="left" overlay={analyzerTooltip}>
                                                <Badge bg="primary" pill>{item.max_difficulty}</Badge>
                                            </OverlayTrigger> */}
                                            </div>
                                        </div>
                                        
                                        <div className='rec-list'>
                                        { achievements && achievements.map((rec,i)=>{
                                            return(
                                            <div key={i}>
                                            {rec.survey_id === item.survey_id ?
                                                <div className="rec-item"
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                    <span><b>Recommendation- </b>{rec.name}</span>
                                                    </div>
                                                    
                                                    <button className="btn btn-success mt-4"
                                                    onClick={() =>
                                                        handleShowRecommendation(rec.recommendation_id)
                                                    }
                                                    >Download Resources</button>
                                                </div>
                                            :<></>}
                                            </div>
                                            )})
                                        }
                                        </div>
                                        
                                        </div>
                                        
                                    </ListGroup.Item>
                                    </ListGroup>
                                </div>
                                )})
                            } 
                            </div>
                            <div>
                            {
                            analyzers.length>0 && <div className="result_pagination mt-4">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={onPageChange}
                                    pageRangeDisplayed={6}
                                    pageCount={totalPages}
                                    previousLabel="<"
                                    activeClassName="active"
                                    disabledClassName="disable"
                                    renderOnZeroPageCount={null}
                                />
                            </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            </LoadingOverlay>
        </div>
        <Footer />
        {/* <div style={{marginTop: "50px"}}>Welcome {this.userdata.name}</div> */}
        </React.Fragment>
    );
}

export default ImplementedRecommendation;
